






































































































import Site from "@/model/Site";
import { Getters } from "@/store/getters";
import { Mutations } from "@/store/mutations";
import Vue from "vue";
import Component from "vue-class-component";
import { MenuItem } from "../../model/MenuItem";

@Component({})
export default class CoreNavigationDrawer extends Vue {
  get items(): MenuItem[] {
    return [
      {
        icon: "mdi-view-dashboard",
        title: "Tableau de bord",
        to: "/",
        disabled: false,
        show: true
      },
      {
        icon: "mdi-playlist-edit",
        title: "Saisie des menus",
        to: "/menus",
        disabled: !this.siteSelected,
        show: true
      },
      {
        icon: "mdi-file-import-outline",
        title: "Import des menus",
        to: "/menus/import",
        disabled: !this.siteSelected,
        show: this.siteSelected && this.accessToImportExportCSV(this.site?.customerId)
      },
      {
        icon: "mdi-file-export-outline",
        title: "Export des menus",
        to: "/menus/export",
        disabled: !this.siteSelected,
        show: this.siteSelected && this.accessToImportExportCSV(this.site?.customerId)
      },
      {
        icon: "mdi-square-edit-outline",
        title: "Saisie des actualités",
        to: "/articles",
        disabled: !this.siteSelected,
        show: true
      }
    ].filter(i => i.show);
  }

  redirectToApp() {
    return {
      icon: "mdi-open-in-new",
      title: "Voir sur l'app",
      href: `${process.env.VUE_APP_APP_CONVIVES_URL}/${this.site?.slug}`,
      target: "_blank",
      disabled: !this.siteSelected
    };
  }

  setDrawer(value: boolean): void {
    this.$store.commit(Mutations.SET_DRAWER, value);
  }

  get drawer(): boolean {
    return this.$store.state.drawer === true;
  }

  get siteSelected(): boolean {
    return this.$store.getters[Getters.SELECTED];
  }

  get site(): Site | null {
    return this.$store.state.site;
  }

  get isAdmin(): boolean {
    return this.$store.getters[Getters.IS_ADMIN];
  }

  get hasBookingConfiguration(): boolean {
    return this.$store.getters[Getters.BOOKING_CONFIGURATION];
  }

  get version() {
    return process.env.VUE_APP_VERSION || "1";
  }

  private accessToImportExportCSV(customerId?: string) {
    const customerIds = ["demo", "Y92", "Y57", "Z20", "A39", "A95", "A46", "A44", "A48", "B48"];
    return customerIds.some(id => id === customerId);
  }
}
